import { Box, Stack, Typography } from "@mui/material";
import { OutlinedButton } from "component/button";
import { commonService } from "forms/helpers/common.service";
import { errorMessage } from "forms/helpers/function.helper";
import { Failed } from "forms/helpers/toast.helper";
import TestimonialCard from "forms/hoc/testimonial-card";
import { useEffect, useState } from "react";
import Slider from "react-slick";

const Testimonials = ({ branding }: any) => {
  const [testimonials, setTestimonials] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const testimonialsSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
    ],
  };
  useEffect(() => {
    getTestimonials();
  }, []);
  const getTestimonials = () => {
    const params = {
      page: 1,
      per_page: 10,
      sort_order: "ASC",
      sort_by: "rating",
    
    };
    commonService
      .postServiceWithParams(`testimonial/testimonials`, null, params)
      .then((response) => {
        setIsLoading(false);
        setTestimonials(response?.data?.data?.results);
      })
      .catch((error) => {
        setIsLoading(false);
        Failed(
          errorMessage(error, "Something went wrong, Please try again later")
        );
      });
  };
  return (
    <Box mt={{ xs: 3, sm: 5 }}>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Typography variant="h3">What veterans say</Typography>
      
      </Stack>
      <Box
        mt={2}
        className="slider-container"
        maxWidth={{ xs: "100vw", sm: "75vw" }}
      >
        <Slider {...testimonialsSettings}>
          {testimonials?.map((testimonial: any) => {
            return (
              <Box mr={1} key={testimonial?._id}>
                <TestimonialCard testimonial={testimonial} />
              </Box>
            );
          })}
        </Slider>
      </Box>
     
    </Box>
  );
};

export default Testimonials;
