import { forwardRef, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Collapse,
  Divider,
  Grid,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
} from "@mui/material";
// project imports
import { MENU_OPEN, SET_MENU } from "features/theme/actions";
import { useLocation } from "react-router-dom";
import Assets from "helpers/assets.helper";
import { useAppSelector } from "store/store";
import { setSubMenuItemsRedux } from "store/slice/user.slice";

// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //

const NavItem = ({ item, level }: NavItemTypes) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const customization = useSelector((state: any) => state?.customization);
  const menuItems = useAppSelector((state) => state.user.menuItems);
  const matchesSM = useMediaQuery(theme.breakpoints.down("lg"));
  const chatNotificationCount: any = useAppSelector((state) => state);

  const [expanded, setExpanded] = useState(false);

  const handleExpansion = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };
  let itemTarget = "_self";
  if (item.target) {
    itemTarget = "_blank";
  }

  

  const itemHandler = (url: any) => {
    navigate(url);
  };

  // active menu item on page load
  useEffect(() => {
    const currentIndex = document.location.pathname
      .toString()
      .split("/")
      .findIndex((id) => id === item.id);
    if (currentIndex > -1) {
      dispatch({ type: MENU_OPEN, id: item.id });
    }
    // eslint-disable-next-line
  }, []);
  const location = useLocation();
  const fileName = location.pathname.split("/");
  const { id } = useParams();
  const pathname = id
    ? location.pathname.replace(`/${id}`, "")
    : location.pathname;
  return (
    <ListItemButton
   
      disabled={item.disabled}
      sx={{
        
        width: "100%",
        backgroundColor: level > 1 ? "transparent !important" : "inherit",
        my: "5px",
        py: "10px",
        display: "flex",
        alignItems: "center",
      
      }}
      selected={
        item?.activeItem && item?.activeItem.length
          ? item?.activeItem?.includes(pathname)
          : `/${item.url}` === pathname
      }
      onClick={() => {
        if (!item.children) {
          itemHandler(item.url);
        }
      }}
     
    >
    
      {(item.title === "Home" || item.title === "Contact us") && (
        <ListItemText
          sx={{ marginLeft: "1rem" }}
          primary={
            <Typography
              variant={
                customization.isOpen.findIndex((id: any) => id === item.id) > -1
                  ? "h5"
                  : "body1"
              }
              color="inherit"
              style={{
                paddingRight: item.title === "Candidate" ? "22px" : "10px",
              }}
            >
              {item.title}
            </Typography>
          }
        />
      )}
      {item.children && (
        <Box>
          <Typography onClick={handleExpansion} sx={{ ml: "15px" }}>
            {item?.title}
            <IconButton
              sx={{
                position: "absolute",
                right: "5px",
                top: "2px",
              }}
            >
              <ExpandMoreIcon />
            </IconButton>
          </Typography>
          <Collapse in={expanded}>
            <Grid container direction="column" sx={{ ml: "25px", mt: 2 }}>
              {item?.children.map((child: any) => {
                return (
                  <Box
                    sx={{ display: "flex", flexDirection: "row" }}
                    onClick={($e: any) => {
                      $e.stopPropagation();
                      itemHandler(child?.url);
                    }}
                  >
                    <ListItemIcon>
                      <img
                        src={child.icon}
                        alt={child.id}
                        style={{ width: "16px" }}
                      />
                    </ListItemIcon>{" "}
                    <pre>
                      <Typography>{child.title}</Typography>
                    </pre>
                  </Box>
                );
              })}
            </Grid>
          </Collapse>
        </Box>
      )}
      {chatNotificationCount?.chat?.chatNotificationCount &&
      item.title === "Chat" ? (
        <div
          style={{
            height: "20px",
            width: "30px",
            backgroundColor: " #bbb",
            borderRadius: "50%",
            display: "inline-block",
            background: "#098bd7",
            fontSize: "10px",
            color: "white",
            textAlign: "center",
            padding: "2px",
            position: "absolute",
            right: "15px",
          }}
        >
          new
        </div>
      ) : (
        <></>
      )}
    </ListItemButton>
  );
};

type NavItemTypes = {
  item: any;
  level: number;
};

export default NavItem;
