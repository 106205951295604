import {
  Box,
  Button,
  Card,
  CardContent,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import R_system from "asset/images/r_system.svg";

import Notification from "asset/icons/notification.svg";
import Location from "asset/icons/location.svg";
import Facebook from "asset/icons/facebook-color.svg";
import Instagram from "asset/icons/instagram-color.svg";
import Youtube from "asset/icons/youtube-color.svg";
import LinkedIn from "asset/icons/linkedin-color.svg";
import Close from "asset/icons/close-icon.svg";
import React, { useState } from "react";
import { InputField, PhoneField } from "forms/hoc/formfield";
import useForm from "forms/hooks/useForm";
import { SubscriptionValidation } from "forms/hooks/validateRules";


const EmployerDetailsJobs = () => {
  const theme = useTheme();
 
  const [open, setOpen] = useState(false);

  const handleRequestCall = () => {

  };
  const RequestCallValues = {
    full_name: null,
    email_id: null,
    mobile_number: "91",
    message: null,
  };
  const { values, errors, handleChange } = useForm(
    RequestCallValues,
    handleRequestCall,
    SubscriptionValidation
  );
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  
  return (
    <>
      <Card
        sx={{
          boxShadow: `0px 4px 12px 0px ${theme?.palette?.primary?.boxShadowLight}`,
        }}
      >
        <CardContent>
          <Box sx={{ display: "flex" }}>
            <Box>
              <img
                src={R_system}
                alt={"RSystem"}
                style={{ width: "180px", height: "180px" }}
              />
            </Box>
            <Box sx={{ marginLeft: "2rem" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  variant="h3"
                  sx={{
                    fontWeight: "600",
                    color: theme?.palette?.primary?.textBtn,
                  }}
                >
                  R System International
                </Typography>
                <Button
                  variant="contained"
                  endIcon={<img src={Notification} alt="Notification" />}
                >
                  Create a job alert
                </Button>
              </Box>
              <Box sx={{ display: "flex" }}>
                <img
                  src={Location}
                  alt={"Location"}
                  style={{ marginRight: "8px" }}
                />
                <Typography
                  sx={{
                    fontWeight: "500",
                    color: theme?.palette?.primary?.textMid,
                  }}
                >
                  Located at Andheri, Mumbai
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  marginTop: "1rem",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "500",
                    color: theme?.palette?.primary?.textMid,
                  }}
                >
                  IT service & solutions
                </Typography>
                <Box
                  sx={{
                    width: "6px",
                    height: "6px",
                    borderRadius: "50%",
                    backgroundColor: theme.palette.primary.buttonDisable,
                    marginLeft: "1rem",
                  }}
                />
                <Typography
                  sx={{
                    fontWeight: "500",
                    color: theme?.palette?.primary?.textMid,
                    marginLeft: "1em",
                  }}
                >
                  Artificial intelligence
                </Typography>

                <Box
                  sx={{
                    width: "6px",
                    height: "6px",
                    borderRadius: "50%",
                    backgroundColor: theme.palette.primary.buttonDisable,
                    marginLeft: "1rem",
                  }}
                />
                <Typography
                  sx={{
                    fontWeight: "500",
                    color: theme?.palette?.primary?.textMid,
                    marginLeft: "1em",
                  }}
                >
                  Cloud & DevOps
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    flex: "1",
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontWeight: "600",
                      color: theme?.palette?.primary?.textBtn,
                      marginRight: "8px",
                    }}
                  >
                    Social Media
                  </Typography>
                  <img
                    src={Facebook}
                    alt={"facebook"}
                    style={{ marginRight: "8px" }}
                  />
                  <img
                    src={Instagram}
                    alt={"instagram"}
                    style={{ marginRight: "8px" }}
                  />
                  <img
                    src={Youtube}
                    alt={"youtube"}
                    style={{ marginRight: "8px" }}
                  />
                  <img
                    src={LinkedIn}
                    alt={"LinkedIn"}
                    style={{ marginRight: "8px" }}
                  />
                </Box>
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontWeight: "500",
                    color: theme?.palette?.primary?.textBtn,
                    marginTop: "1rem ",
                  }}
                >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                  semper nunc erat, ut auctor lorem vulputate a. Pellentesque
                  habitant morbi tristique senectus et netus et malesuada fames
                  ac turpis.
                </Typography>
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Card>
     
      <Box> </Box>
      <DialogTitle sx={{ width: "450px" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="subtitle1" sx={{ fontWeight: "600" }}>
            Enquire form
          </Typography>
          <IconButton onClick={handleClose}>
            <img src={Close} alt={"close"} />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ width: "450px" }}>
        <Box mb={2}>
          <InputField
            fieldName="Full name"
            values={values?.full_name || ""}
            name="Full name"
            isRequired={false}
            errors={errors?.full_name}
            handleChange={handleChange}
            id="Full name"
          />
        </Box>
        <Box mb={2}>
          <InputField
            fieldName="Email ID"
            values={values?.email_id || ""}
            errors={errors?.email_id}
            handleChange={handleChange}
            name="Email ID"
            isRequired={false}
            id="Email ID"
          />
        </Box>
        <Box mb={2}>
          <PhoneField
            name="mobile_number"
            fieldName="Mobile Number"
            value={values?.mobile_number || ""}
            errors={errors?.mobile_number}
            handleChange={handleChange}
            id="mobile_number"
            isRequired={false}
          />
        </Box>
        <Box mb={2}>
          <InputField
            values={values.description || ""}
            fieldName="Message"
            handleChange={handleChange}
            name="description"
            errors={errors.description}
            multiline={true}
            minRow={3}
            id="description"
          />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }} mt={1}>
          <Typography variant="caption">50/150</Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          sx={{ padding: "12px, 24px, 12px, 24px ", width: "360px" }}
        >
          Request a call
        </Button>
      </DialogActions>
    </>
  );
};

export default EmployerDetailsJobs;
