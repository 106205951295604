import {
  Box,
  Card,
  Dialog,
  Grid,
  CardContent,
  Typography,
  useTheme,
  DialogContent,
  Stack,
} from "@mui/material";
import { InputField, SelectField } from "forms/hoc/formfield";
import SearchIcon from "asset/icons/search.svg";
import React, { useEffect, useState } from "react";
import useForm from "forms/hooks/useForm";
import {
  CandidatesSearchValidationSchema,
} from "forms/hooks/validateRules";
import { ContainedButton, OutlinedButton } from "component/button";
import LocationColorIcon from "asset/icons/location-color.svg";
import ExperienceColorIcon from "asset/icons/experience-color.svg";
import CandidateCard from "forms/hoc/candidate-card";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import { commonService } from "forms/helpers/common.service";
import { errorMessage } from "forms/helpers/function.helper";
import { Failed } from "forms/helpers/toast.helper";
import Loader from "ui-component/Loader";
import ExploreEmployerBanner from '../../../../asset/images/explore-employer.jpeg';

const FindCandidate = () => {
  const [filterOpen, setFilterOpen] = useState(false);
  const [allCandidates, setAllCandidates] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const initialValues = {
    department: null,
    location: null,
    search: null,
  };
  const handleSearchSubmit = () => {
    navigate("/employer/all-candidates", {
      state: {
        department: values.department,
        location: values.location,
        search: values.search,
      },
    });
   
  };
  const { errors, values, handleChange, handleSubmit, setUpdateValue } =
    useForm(
      initialValues,
      handleSearchSubmit,
      CandidatesSearchValidationSchema
    );
  const navigate = useNavigate();
  const allCategories = [
    {
      name: "category1",
      value: "category1",
    },
  ];
  const [locations, setLocations] = useState([]);
  const [departments, setDepartments] = useState([]);
  const candidatesSliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  useEffect(() => {
    getCandidates();
    getLocations();
    getDepartments();
  }, []);

  const getCandidates = () => {
    setIsLoading(true);
    commonService
      .getServices(`candidate/department`)
      .then((response) => {
        setIsLoading(false);
        setAllCandidates(response?.data?.data);
      })
      .catch((error) => {
        setIsLoading(false);
        Failed(
          errorMessage(error, "Something went wrong, Please try again later")
        );
      });
  };

  const getLocations = () => {
    const params = {
      page: 1,
      per_page: 10,
      sort_order: "ASC",
      sort_by: "created_at",
    };
    commonService
      .postServiceWithParams(`master/locations`, null, params)
      .then((response) => {
        setIsLoading(false);
        const jobLocations = response?.data?.data?.results?.map(
          (location: any) => {
            return {
              name: location?.city,
              value: location?._id,
            };
          }
        );
        setLocations(jobLocations);
      })
      .catch((error) => {
        setIsLoading(false);
        Failed(
          errorMessage(error, "Something went wrong, Please try again later")
        );
      });
  };

  const getDepartments = () => {
    setIsLoading(true);
    commonService
      .postService(`master/categories`, null)
      .then((response) => {
        setIsLoading(false);
        const jobCategories = response?.data?.data?.results?.map(
          (category: any) => {
            return {
              name: category?.name,
              value: category?._id,
            };
          }
        );
        setDepartments(jobCategories);
      })
      .catch((error) => {
        setIsLoading(false);
        Failed(
          errorMessage(error, "Something went wrong, Please try again later")
        );
      });
  };

  return (
    <>
      {isLoading && <Loader />}
      <Box
        sx={{
          backgroundImage: `url(${ExploreEmployerBanner})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: 'center',
          minHeight: "386px",
          py: { xs: 1, sm: 5 },
          px: { xs: "2%", sm: "7.5rem" },
          mx: { xs: "-2%", sm: "-7.5rem" },
        }}
      >
        <Grid container columnSpacing={2}>
          <Grid item xs={12} sm={6}>
            <Box
              onClick={() => {
                setFilterOpen(true);
              }}
              mt={{ xs: 1, sm: 3 }}
              mb={{ xs: 2, sm: 3 }}
              sx={{ display: { xs: "block", sm: "none" } }}
            >
              <InputField
                values={values?.search || ""}
                name="search"
                errors={errors?.search}
                handleChange={() => {}}
                placeholder="Search jobs"
                id="search"
                adorament="startAdornment"
                position="start"
                Icon={<img src={SearchIcon} alt="Search" />}
              />
            </Box>
            <Card
              sx={{
                maxWidth: "480px",
                borderRadius: "12px",
                display: { xs: "none", sm: "block" },
              }}
            >
              <CardContent>
                <form onSubmit={handleSubmit}>
                  <Grid spacing={2} container>
                    <Grid item xs={12}>
                      <InputField
                        values={values?.search || ""}
                        name="search"
                        errors={errors?.search}
                        handleChange={handleChange}
                        placeholder="Search by skills, designation, companies"
                        id="search"
                        adorament="startAdornment"
                        position="start"
                        Icon={<img src={SearchIcon} alt="Search" />}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <SelectField
                        values={values.department}
                        name="department"
                        errors={""}
                        handleChange={handleChange}
                        options={departments}
                        placeholder="All departments"
                        id="department"
                        isRequired={false}
                        adorament="startAdornment"
                        position="start"
                 
                        Icon={<img src={ExperienceColorIcon} alt="Search" />}
                      />
                    
                    </Grid>
                    <Grid item xs={6}>
                      <SelectField
                        values={values.location}
                        name="location"
                        errors={""}
                        handleChange={handleChange}
                        options={locations}
                        placeholder="Preferred Location"
                        adorament="startAdornment"
                        position="start"
                        Icon={<img src={LocationColorIcon} alt="Search" />}
                        isRequired={false}
                        id="location"
                      />
                    </Grid>

                    <Grid xs={12} pl={2} pt={2}>
                      <ContainedButton
                        size="large"
                        label="Find Candidates"
                        type="submit"
                        isFullWidth={true}
                      />
                    </Grid>
                  </Grid>
                </form>
              </CardContent>
            </Card>
            <Dialog
              open={filterOpen}
              onClose={() => {
                setFilterOpen(false);
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogContent sx={{ p: 1 }}>
                <Card
                  sx={{
                    maxWidth: "480px",
                    borderRadius: "12px",
                  }}
                >
                  <CardContent sx={{ p: ".57rem !important" }}>
                    <form onSubmit={handleSubmit}>
                      <Grid spacing={2} container>
                        <Grid item xs={12}>
                          <SelectField
                            values={values.department}
                            name="department"
                            errors={""}
                            handleChange={($e) => {
                          
                            }}
                            options={departments}
                            placeholder="All departments"
                            id="department"
                            isRequired={false}
                            adorament="startAdornment"
                            position="start"
                            isGroup={true}
                            Icon={
                              <img src={ExperienceColorIcon} alt="Search" />
                            }
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <SelectField
                            values={values.location}
                            name="location"
                            errors={""}
                            handleChange={handleChange}
                            options={allCategories}
                            placeholder="Preferred Location"
                            adorament="startAdornment"
                            position="start"
                            Icon={<img src={LocationColorIcon} alt="Search" />}
                            isRequired={false}
                            id="location"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <InputField
                            values={values?.search || ""}
                            name="search"
                            errors={errors?.search}
                            handleChange={handleChange}
                            placeholder="Search by skills, designation, companies"
                            id="search"
                            adorament="startAdornment"
                            position="start"
                            Icon={<img src={SearchIcon} alt="Search" />}
                          />
                        </Grid>
                        <Grid
                          xs={12}
                          pl={2}
                          pt={2}
                          display={"flex"}
                          justifyContent={"space-between"}
                          gap={2}
                        >
                          <OutlinedButton
                            size="large"
                            label="Cancel"
                            type="submit"
                            isFullWidth={true}
                          />
                          <ContainedButton
                            size="large"
                            label="Find Jobs"
                            type="submit"
                            isFullWidth={true}
                          />
                        </Grid>
                      </Grid>
                    </form>
                  </CardContent>
                </Card>
              </DialogContent>
            </Dialog>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Box sx={{
              backdropFilter: {
                xs: 'blur(5px)',
                md: 'blur(1px)'
              }
            }}>
              <Typography
                fontSize={{ xs: "2rem", sm: "3rem" }}
                color={theme?.palette?.primary?.textBtn}
                fontWeight={600}
              >
                Discover top talent
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: "1rem", sm: "1.125rem" },
                  maxWidth: "467px",
                  fontWeight: 500,
                  color: "white",
                  mb: 3,
                  mt: 2,
                }}
              >
                Explore veteran candidates ready to make an impact on your team!
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box>
        {allCandidates.map((data: any) => {
          return (
            <Box my={{ xs: 2, sm: 5 }}>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Typography variant="h3">{data?.department}</Typography>
                <Box display={{ xs: "none", sm: "block" }}>
                  <OutlinedButton
                    label="View all candidates"
                    size="small"
                    isBold={true}
                    handleClick={() => {
                      navigate("../all-candidates");
                    }}
                  />
                </Box>
              </Stack>
              <Box
                my={3}
                className="slider-container"
                width={{ xs: "calc(100vw - 4%)", sm: "calc(100vw - 18rem)" }}
              >
                {data?.candidates?.length >= 3 && (
                  <Slider
                    {...candidatesSliderSettings}
               
                  >
                    {data?.candidates?.map((candidate: any) => {
                      return (
                        <Box pr={2}>
                          <CandidateCard
                            handleCandidateClick={() => {
                              navigate(
                                `/employer/candidate-details/${candidate?._id}`
                              );
                            }}
                            candidate={candidate}
                          />
                        </Box>
                      );
                    })}
                  </Slider>
                )}
                {/* {data?.candidates?.length === 1 && (
                  <Box pr={2} maxWidth={"300px"}>
                    <CandidateCard
                      handleCandidateClick={() => {
                        navigate(
                          `/employer/candidate-details/${data?.candidates[0]?._id}`
                        );
                      }}
                      candidate={data?.candidates[0]}
                    />
                  </Box>
                )} */}
                {data?.candidates?.length < 3 && (
                  <Grid container spacing={2}>
                    {data?.candidates?.map((candidate: any) => {
                      return (
                        <Grid item xs={12} sm={6} md={4}>
                          <Box pr={2}>
                            <CandidateCard
                              handleCandidateClick={() => {
                                navigate(
                                  `/employer/candidate-details/${candidate?._id}`
                                );
                              }}
                              candidate={candidate}
                            />
                          </Box>
                        </Grid>
                      );
                    })}
                  </Grid>
                )}
              </Box>
              <Box textAlign={"center"} display={{ xs: "block", sm: "none" }}>
                <OutlinedButton
                  handleClick={() => {
                    navigate("../all-candidates");
                  }}
                  label="View all candidates"
                  size="small"
                  isBold={true}
                />
              </Box>
            </Box>
          );
        })}
      </Box>
    </>
  );
};
export default FindCandidate;
