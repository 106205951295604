import {
  Button,
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useEffect, useState } from "react";

export const Dropdown = ({
  label,
  options,
  isMultiSelect,
  id,
  handleSelect,
  selected,
  selectedItems,
  min,
  isActive,
  isInverse,
  hasValueLabel,
  isSquare,
}: {
  label: string;
  options: any[];
  isMultiSelect?: boolean;
  id: string;
  handleSelect: (value: any) => void;
  selected?: any;
  selectedItems?: any[];
  min?: number;
  isActive?: any;
  isInverse?: boolean;
  hasValueLabel?: boolean;
  isSquare?: boolean;
}) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [checked, setChecked] = useState<any>([]);
  const [disable, setDisable] = useState(false);
  const [checkDisable, setCheckDisable] = useState(false);

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
   
    if (isMultiSelect && selectedItems) {
      setChecked([...selectedItems!]);
    } else {
      setChecked([]);
    }
    if (selectedItems?.length === min) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, [selectedItems]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleToggle = (value: number) => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    console.log("Splice", currentIndex);
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      console.log("Splice");
      newChecked.splice(currentIndex, 1);
    }

    if (newChecked.length === min) {
      console.log("Disabled");
      setDisable(true);
    } else {
      console.log("Enabled");
      setDisable(false);
    }

    if (newChecked.length == 1) {
      setCheckDisable(true);
    } else {
      setCheckDisable(false);
    }

    handleSelect(newChecked);
    setChecked(newChecked);
  };

  return (
    <>
      <Button
        id="demo-positioned-button"
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={($e) => {
          handleClick($e);
        }}
        endIcon={
          <KeyboardArrowDownIcon
            sx={{
              color: isInverse
                ? theme?.palette?.primary?.textBtn
                : theme?.palette?.primary?.iconOrange,
            }}
          />
        }
        variant="outlined"
        sx={{
          backgroundColor: isInverse
            ? theme?.palette?.primary?.buttonOrange
            : "transparent",
          borderColor: isActive
            ? theme?.palette?.primary?.buttonOrange
            : theme?.palette?.divider,
          "&:hover": {
            backgroundColor: isInverse
              ? theme?.palette?.primary?.buttonOrange
              : "transparent",
          },
          borderRadius: isSquare ? "8px" : "48px",
          p: { md: ".6rem .8rem", lg: ".75rem 1.5rem" },
        }}
      >
        {hasValueLabel && selected ? capitalizeFirstLetter(selected) : label}
      </Button>
      {isMultiSelect ? (
        <Menu
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <List
            sx={{
              width: "100%",
              maxWidth: 360,
              bgcolor: "background.paper",
              px: 1,
              py: 0.5,
            }}
          >
            {options?.map((option) => {
              const labelId = `checkbox-list-label-${option?.value}`;

              return (
                <ListItem key={option?.value} disablePadding>
                  <ListItemButton
                    role={undefined}
                    onClick={() => {
                      console.log("Clicked");
                      if (disable) {
                        console.log("comes");
                        if (checked.indexOf(option?.value) !== -1) {
                          console.log("there");
                          handleToggle(option?.value);
                        }
                      } else {
                        if (checkDisable) {
                          if (checked.indexOf(option?.value) === -1) {
                            handleToggle(option?.value);
                          }
                        } else {
                          handleToggle(option?.value);
                        }
                      }
                      // disable
                      //   ? checked.indexOf(option?.value) !== -1
                      //     ? handleToggle(option?.value)
                      //     : () => {}
                      //   : handleToggle(option?.value);
                    }}
                    dense
                    sx={{ py: 0.5 }}
                  >
                    <ListItemIcon sx={{ minWidth: "20px" }}>
                      <Checkbox
                        edge="start"
                        checked={checked.indexOf(option?.value) !== -1}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ "aria-labelledby": labelId }}
                        sx={{ p: 0 }}
                        disabled={
                          (disable && checked.indexOf(option?.value) === -1) ||
                          (checkDisable &&
                            checked.indexOf(option?.value) !== -1)
                        }
                      />
                    </ListItemIcon>
                    <ListItemText
                      id={labelId}
                      primary={
                        <Typography
                          sx={{
                            fontSize: ".875rem",
                            fontWeight: 500,
                            color: "#15171E",
                          }}
                        >
                          {option?.name}
                        </Typography>
                      }
                    />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </Menu>
      ) : (
        <Menu
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          {options.map((option) => {
            return (
              <MenuItem
                selected={selected === option?.value}
                onClick={() => {
                  handleSelect(option?.value);
                  handleClose();
                }}
              >
                {option.name}
              </MenuItem>
            );
          })}
        </Menu>
      )}
    </>
  );
};

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
