import ExploreEmployerIcon from "asset/images/explore-employer.svg";
import DashboardIcon from "asset/images/candidate-dashboard.svg";
import FindCandidateIcon from "asset/images/find-candidate.svg";
import AccountIcon from "asset/icons/account_active.svg";
import ExperienceIcon from "asset/icons/experience-color.svg";
import SettingIcon from "asset/icons/settings.svg";
import LogoutIcon from "asset/icons/logout-circle.svg";
import QueryIcon from "asset/icons/query.svg";
import SubscriptionIcon from "asset/icons/subscription.svg";
import Feedback from "asset/icons/feedback.svg";

const Menu = [
  {
    id: `dashboard`,
    title: `Dashboard`,
    type: `group`,
    children: [
      {
        id: `home`,
        title: `Home`,
        type: `item`,
        url: `/employer/home`,
        icon: ``,
        breadcrumbs: false,
        parentId: "member_portal",
        activeItem: [`/employer/home`],
      },
      {
        id: `employer`,
        title: `Employer`,
        type: `item`,
        url: ``,
        icon: ``,
        breadcrumbs: false,
        parentId: "member_portal",
        activeItem: [
          "/employer/find-candidate",
          "/employer/all-candidates",
          "/employer/dashboard",
          "/employer/candidate-details",
        ],
        children: [
          {
            id: "ekam-for-employer",
            title: "Ekam for employer",
            type: "child",
            url: "/employer/home",
            icon: ExploreEmployerIcon,
            breadcrumbs: false,
          },
          {
            id: "find-candidate",
            title: "Find Candidate",
            type: "child",
            url: "/employer/find-candidate",
            icon: FindCandidateIcon,
            breadcrumbs: false,
          },
          {
            id: "employer-dashboard",
            title: "Employer Dashboard",
            type: "child",
            url: "/employer/dashboard",
            icon: DashboardIcon,
            breadcrumbs: false,
          },
          {
            id: "raise-a-query",
            title: "Raise a Query",
            type: "child",
            url: "/employer/raise-query",
            icon: QueryIcon,
            breadcrumbs: false,
          },
        ],
      },
      {
        id: `contactus`,
        title: `Contact us`,
        type: `item`,
        url: "/contact-us",
        icon: ``,
        breadcrumbs: false,
        parentId: "member_portal",
        activeItem: [],
      },
    ],
  },
];

export default Menu;

export const EmployerProfileMenu = [
  {
    id: "profile",
    title: "Profile",
    type: "child",
    url: "/employer/profile",
    icon: AccountIcon,
    breadcrumbs: false,
  },
  {
    id: "my-jobs",
    title: "My Jobs",
    type: "child",
    url: "/employer/my-jobs",
    icon: ExperienceIcon,
    breadcrumbs: false,
  },
  {
    id: "my-plans",
    title: "My Plan",
    type: "child",
    url: "/employer/subscription-details",
    icon: SubscriptionIcon,
    breadcrumbs: false,
  },
  {
    id: "setting",
    title: "Settings",
    type: "child",
    url: "/employer/employer-setting",
    icon: SettingIcon,
    breadcrumbs: false,
  },
  {
    id: "feedback",
    title: "Feedback",
    type: "child",
    url: "/feedback",
    icon: Feedback,
    breadcrumbs: false,
  },
  {
    id: "logout",
    title: "Logout",
    type: "child",
    url: "",
    icon: LogoutIcon,
    breadcrumbs: false,
  },
];
