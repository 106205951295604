import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import LinkedIn from "asset/icons/linked-in.svg";
import FaceBook from "asset/icons/facebook-icon.svg";
import Instagram from "asset/icons/instagram-icon.svg";
import EditIcon1 from "asset/icons/edit.svg";
import { InputField, PhoneField, SelectField } from "forms/hoc/formfield";
import useForm from "forms/hooks/useForm";
import { PersonalValidationSchema } from "forms/hooks/validateRules";
import { ContainedButton, OutlinedButton } from "component/button";
import SocialMedia from "./social-media";
import { commonService } from "forms/helpers/common.service";
import { Failed, Success } from "forms/helpers/toast.helper";
import { errorMessage } from "forms/helpers/function.helper";
import Loader from "ui-component/Loader";
import { COMPANY_SIZE } from "constant/options";
import { useAppSelector } from "store/store";
import { getCountryCode, getPhoneNumber } from "helpers/function.helper";
import {
  parsePhoneFormatNumber,
  parsePhoneNumber,
} from "forms/hooks/customHook";
import AboutEdit from "./about-edit";
import EmptyImage from "asset/icons/empty.svg";
import AutocompleteInput from "forms/hoc/formfield/AutocompleteInput";

const BasicInfo = ({ data, handleUpdate }: any) => {
  const theme = useTheme();
  const [basicInfoEditing, setBasicInfoEditing] = useState(false);
  const [socialMediaEditing, setSocialMediaEditing] = useState(false);
  const [locations, setLocations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [socialMediaData, setSocialMediaData] = useState<any>(null);
  const [aboutEditOpen, setAboutEditOpen] = useState(false);
  const [selectedBenefits, setSelectedBenefits] = useState([]);
  const [benefits, setBenefits] = useState([]);
  const user = useAppSelector((state) => state?.user?.user);

  const handleEdit = () => {
    setPatchValue({
      ...data,
      size: data?.employer?.size,
      location: data?.employer?.location?._id,
      address_1: data?.employer?.address_1,
      address_2: data?.employer?.address_2,
      pincode: data?.employer?.pincode,
      mobile_number: data?.country_code + data?.mobile_number,
      benefits: data?.employer?.benefits?.map((benefit: any) => {
        return { name: benefit?.name, value: benefit?._id };
      }),
    });
    setBasicInfoEditing(true);
    setSelectedBenefits(
      data?.employer?.benefits?.map((item: any) => {
        return { name: item?.name, value: item?._id };
      })
    );
  };

  const handleClose = () => {
    setBasicInfoEditing(false);
    setSelectedBenefits([]);
  };
  const socialMediaEdit = () => {
    setSocialMediaData({
      linkedin: data?.employer?.linkedin,
      facebook: data?.employer?.facebook,
      instagram: data?.employer?.instagram,
    });

    setSocialMediaEditing(true);
  };
  const socialMediaClose = () => {
    setSocialMediaEditing(false);
  };

  const handleUpdateBasicInfo = () => {
    const mobile_number = parsePhoneNumber(values.mobile_number);
    let payload = {};
    if (user?.is_primary) {
      payload = {
        first_name: values.first_name,
        last_name: values?.last_name,
        location: values?.location,
        email: values?.email,
        size: parseInt(values.size),
        mobile_number: mobile_number?.mobileNumber,
        country_code: mobile_number?.countryCode,
        address_1: values?.address_1,
        address_2: values?.address_2,
        pincode: values?.pincode,
        benefits: values?.benefits?.map((benefit: any) => benefit?.value),
      };
    } else {
      payload = {
        first_name: values.first_name,
        last_name: values?.last_name,
        email: values?.email,
        mobile_number: mobile_number?.mobileNumber,
        country_code: mobile_number?.countryCode,
      };
    }

    handleUpdate(payload);
    setBasicInfoEditing(false);
  };

  const initialLoginValues = {
    first_name: data?.first_name,
    last_name: data?.last_name,
    mobile_number: data?.country_code + data?.mobile_number,
    size: data?.employer?.size,
    location: data?.location?._id,
    email: data?.email,
    address_1: data?.employer?.address_1,
    address_2: data?.employer?.address_2,
    pincode: data?.employer?.pincode,
    benefits: data?.employer?.benefits,
  };

  const {
    values,
    errors,
    handleChange,
    setUpdateValue,
    handleSubmit,
    setPatchValue,
  } = useForm(
    initialLoginValues,
    handleUpdateBasicInfo,
    PersonalValidationSchema
  );

  useEffect(() => {
   
    getLocations();
    getBenefits();
  }, []);



  const getLocations = () => {
    const params = {
      page: 1,
      per_page: 10,
      sort_order: "ASC",
      sort_by: "created_at",
    };
    commonService
      .postServiceWithParams(`master/locations`, null, params)
      .then((response) => {
        setIsLoading(false);
        const jobLocations = response?.data?.data?.results?.map(
          (location: any) => {
            return {
              name: location?.city,
              value: location?._id,
            };
          }
        );
        setLocations(jobLocations);
      })
      .catch((error) => {
        setIsLoading(false);
        Failed(
          errorMessage(error, "Something went wrong, Please try again later")
        );
      });
  };

  const getBenefits = () => {
    commonService
      .postService(`master/benefits?per_page=100`, null)
      .then((response) => {
        setIsLoading(false);
        const jobBenefits = response?.data?.data?.results?.map(
          (benefit: any) => {
            return {
              name: benefit?.name,
              value: benefit?._id,
            };
          }
        );
        setBenefits(jobBenefits);
      })
      .catch((error) => {
        setIsLoading(false);
        Failed(
          errorMessage(error, "Something went wrong, Please try again later")
        );
      });
  };

  return (
    <>
      {isLoading && <Loader />}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={7} sx={{ display: "flex" }}>
          <Card
            sx={{
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              height: "100%",
              width: "100%",
            }}
          >
            <CardContent sx={{ padding: "0px !important" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  p: { xs: 1, sm: 2 },
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{
                    fontWeight: 600,
                    color: theme?.palette?.primary?.textBtn,
                  }}
                >
                  Basic Info
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    color: "#AB5B0B",
                  }}
                  onClick={handleEdit}
                  style={{ cursor: "pointer" }}
                >
                  <img src={EditIcon1} alt="edit" />
                  <Typography
                    sx={{ pl: 1, fontWeight: 600, fontSize: ".875rem" }}
                  >
                    Edit
                  </Typography>
                </Box>
              </Box>
              <Divider sx={{ mb: 2 }} />
              <Grid container spacing={2} sx={{ px: { xs: 1, sm: 2 } }}>
                <Grid item xs={6} lg={4}>
                  <Typography
                    fontSize={".875rem"}
                    fontWeight={500}
                    marginBottom={1}
                    color={"#616B73"}
                  >
                    First name
                  </Typography>
                  {data?.first_name ? (
                    <Typography
                      fontSize={"1rem"}
                      fontWeight={500}
                      marginBottom={1}
                      color={"#15171E"}
                    >
                      {data?.first_name}
                    </Typography>
                  ) : (
                    <Typography
                      fontSize={"1rem"}
                      fontWeight={500}
                      marginBottom={1}
                      color={"#15171E"}
                    >
                      No first name
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={6} lg={4}>
                  <Typography
                    fontSize={".875rem"}
                    fontWeight={500}
                    marginBottom={1}
                    color={"#616B73"}
                  >
                    Last name
                  </Typography>
                  {data?.last_name ? (
                    <Typography
                      fontSize={"1rem"}
                      fontWeight={500}
                      marginBottom={1}
                      color={"#15171E"}
                    >
                      {data?.last_name}
                    </Typography>
                  ) : (
                    <Typography
                      fontSize={"1rem"}
                      fontWeight={500}
                      marginBottom={1}
                      color={"#15171E"}
                    >
                      No last name
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={6} lg={4}>
                  <Typography
                    fontSize={".875rem"}
                    fontWeight={500}
                    marginBottom={1}
                    color={"#616B73"}
                  >
                    Email ID
                  </Typography>
                  {data?.email ? (
                    <Typography
                      fontSize={"1rem"}
                      fontWeight={500}
                      marginBottom={1}
                      color={"#15171E"}
                      sx={{
                        wordWrap: "break-word",
                      }}
                    >
                      {data?.email}
                    </Typography>
                  ) : (
                    <Typography
                      fontSize={"1rem"}
                      fontWeight={500}
                      marginBottom={1}
                      color={"#15171E"}
                    >
                      No email ID
                    </Typography>
                  )}
                </Grid>

                <Grid item xs={6} paddingTop={0} lg={4}>
                  <Typography
                    fontSize={".875rem"}
                    fontWeight={500}
                    marginBottom={1}
                    color={"#616B73"}
                  >
                    Mobile number
                  </Typography>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      fontSize={"1rem"}
                      fontWeight={500}
                      marginBottom={1}
                      color={"#15171E"}
                    >
                      {data?.country_code + " " + data?.mobile_number ||
                        "No mobile number"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} lg={4}>
                  <Typography
                    fontSize={".875rem"}
                    fontWeight={500}
                    marginBottom={1}
                    color={"#616B73"}
                  >
                    Organisation size
                  </Typography>
                  {data?.employer?.size ? (
                    <Typography
                      fontSize={"1rem"}
                      fontWeight={500}
                      marginBottom={1}
                      color={"#15171E"}
                    >
                      {data?.employer?.size}
                    </Typography>
                  ) : (
                    <Typography
                      fontSize={"1rem"}
                      fontWeight={500}
                      marginBottom={1}
                      color={"#15171E"}
                    >
                      No organisation size
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={6} lg={4}>
                  <Typography
                    fontSize={".875rem"}
                    fontWeight={500}
                    marginBottom={1}
                    color={"#616B73"}
                  >
                    Head office location
                  </Typography>
                  {data?.employer?.location?.city ? (
                    <Typography
                      fontSize={"1rem"}
                      fontWeight={500}
                      marginBottom={1}
                      color={"#15171E"}
                    >
                      {data?.employer?.location?.city}
                    </Typography>
                  ) : (
                    <Typography
                      fontSize={"1rem"}
                      fontWeight={500}
                      marginBottom={1}
                      color={"#15171E"}
                    >
                      No head office location
                    </Typography>
                  )}
                </Grid>

                <Grid item xs={6} lg={4}>
                  <Typography
                    fontSize={".875rem"}
                    fontWeight={500}
                    marginBottom={1}
                    color={"#616B73"}
                  >
                    Organisation pincode
                  </Typography>
                  {data?.employer?.pincode ? (
                    <Typography
                      fontSize={"1rem"}
                      fontWeight={500}
                      marginBottom={1}
                      color={"#15171E"}
                    >
                      {data?.employer?.pincode}
                    </Typography>
                  ) : (
                    <Typography
                      fontSize={"1rem"}
                      fontWeight={500}
                      marginBottom={1}
                      color={"#15171E"}
                    >
                      No Organisation pincode
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={6} lg={4}>
                  <Typography
                    fontSize={".875rem"}
                    fontWeight={500}
                    marginBottom={1}
                    color={"#616B73"}
                  >
                    Benefits
                  </Typography>
                  {data?.employer?.benefits ? (
                    <Box display={"flex"} gap={1}>
                      <Typography
                        fontSize={"1rem"}
                        fontWeight={500}
                        marginBottom={1}
                        color={"#15171E"}
                      >
                        {data?.employer?.benefits[0]?.name || "No Benefits"}
                      </Typography>
                      {data?.employer?.benefits?.length > 1 && (
                        <Tooltip
                          title={data?.employer?.benefits
                            ?.map((item: any) => item.name)
                            .join(", ")}
                          disableHoverListener={
                            data?.employer?.benefits?.length <= 1
                          }
                        >
                          <Typography
                            variant="body2"
                            fontWeight={600}
                            component={"span"}
                            color={theme?.palette?.primary?.iconOrange}
                            sx={{ cursor: "pointer" }}
                          >
                            +{data?.employer?.benefits?.length - 1} more
                          </Typography>
                        </Tooltip>
                      )}
                    </Box>
                  ) : (
                    <Typography
                      fontSize={"1rem"}
                      fontWeight={500}
                      marginBottom={1}
                      color={"#15171E"}
                    >
                      No Benefits
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Dialog open={basicInfoEditing}>
          <DialogTitle
            sx={{
              fontWeight: 700,
              fontSize: "20px",
              color: " #000000",
            }}
          >
            Edit Basic Info
          </DialogTitle>
          <Divider sx={{ mb: 1 }} />
          <DialogContent sx={{ p: "20px" }}>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <InputField
                    fieldName="First name"
                    values={values.first_name}
                    name="first_name"
                    errors={errors.first_name}
                    handleChange={handleChange}
                    isRequired={false}
                    id="first_name"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputField
                    fieldName="Last name"
                    values={values.last_name}
                    name="last_name"
                    errors={errors.last_name}
                    handleChange={handleChange}
                    isRequired={false}
                    id="last_name"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputField
                    fieldName="Organisation Email ID"
                    values={values.email}
                    name="email"
                    errors={errors.email}
                    handleChange={handleChange}
                    isRequired={false}
                    id="email"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <PhoneField
                    name="mobile_number"
                    fieldName="Mobile Number"
                    value={values?.mobile_number || ""}
                    errors={errors?.mobile_number}
                    handleChange={handleChange}
                    id="mobile_number"
                    isRequired={false}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <InputField
                    fieldName="Organisation Size"
                    values={values.size}
                    name="size"
                    errors={errors.size}
                    handleChange={handleChange}
                    disabled={!user?.is_primary}
                    isRequired={false}
                    onlyNumber={true}
                    id="size"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SelectField
                    fieldName="Head Office Location"
                    values={values.location}
                    name="location"
                    errors={errors.location}
                    handleChange={handleChange}
                    options={locations}
                    disabled={!user?.is_primary}
                    placeholder="select"
                    id="location"
                    isRequired={false}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputField
                    fieldName="Address Line 1"
                    values={values.address_1}
                    name="address_1"
                    disabled={!user?.is_primary}
                    errors={errors.address_1}
                    handleChange={handleChange}
                    isRequired={false}
                    id="address_1"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputField
                    fieldName="Address Line 2"
                    values={values.address_2}
                    name="address_2"
                    disabled={!user?.is_primary}
                    errors={errors.address_2}
                    handleChange={handleChange}
                    isRequired={false}
                    id="address_2"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputField
                    fieldName="Pincode"
                    values={values.pincode}
                    name="pincode"
                    disabled={!user?.is_primary}
                    errors={errors.pincode}
                    handleChange={handleChange}
                    isRequired={false}
                    onlyNumber={true}
                    id="pincode"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    py="10px"
                    fontWeight={400}
                    fontSize={".875rem"}
                    color={theme.palette.text.dark}
                  >
                    Benefits & Perks
                  </Typography>
                  <AutocompleteInput
                    fieldName={"Benefits"}
                    options={benefits}
                    name="benefits"
                    errors={errors?.benefits}
                    value={selectedBenefits}
                    placeholder={"Search Benefits & Perks"}
                    disabled={!user?.is_primary}
                    setOptions={setBenefits}
                    masterType={"benefit"}
                    setValue={(val: any) => {
                   
                      setSelectedBenefits(val);
                      setUpdateValue("benefits", val);
                    }}
                  />
                </Grid>
              </Grid>
              <Box display={"flex"} justifyContent={"end"} mt={3}>
                <OutlinedButton
                  size={"small"}
                  label={"cancel"}
                  isBold={true}
                  handleClick={handleClose}
                />
                <Box sx={{ mx: 1 }} />
                <ContainedButton
                  size="large"
                  isBold={true}
                  label={"Update"}
                  type="submit"
                />
              </Box>
            </form>
          </DialogContent>
        </Dialog>
        <Grid item xs={12} sm={12} md={5} sx={{ display: "flex" }}>
          <Card
            sx={{
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              height: "100%",
              width: "100%",
            }}
          >
            <CardContent sx={{ padding: "0px !important" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  p: { xs: 1, sm: 2 },
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{
                    fontWeight: 600,
                    color: theme?.palette?.primary?.textBtn,
                  }}
                >
                  Social Media Links
                </Typography>
                {user?.is_primary && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      color: "#AB5B0B",
                    }}
                    onClick={socialMediaEdit}
                    style={{ cursor: "pointer" }}
                  >
                    <img src={EditIcon1} alt="edit" />
                    <Typography
                      sx={{ pl: 1, fontWeight: 600, fontSize: ".875rem" }}
                    >
                      Edit
                    </Typography>
                  </Box>
                )}
              </Box>
              <Divider sx={{ mb: 2 }} />
              {!data?.employer?.linkedin &&
                !data?.employer?.facebook &&
                !data?.employer?.instagram && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "200px",
                      p: 3,
                    }}
                  >
                    <img src={EmptyImage} alt="empty" />
                    <Typography
                      variant="body1"
                      fontWeight={600}
                      color={theme?.palette?.primary?.textBtn}
                    >
                      No Social Media Links available
                    </Typography>
                  </Box>
                )}
              <Box sx={{ px: { xs: 1, sm: 2 } }}>
                {data?.employer?.linkedin && (
                  <CardContent sx={{ padding: 0 }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-start",
                      }}
                    >
                      <img
                        src={LinkedIn}
                        height={16}
                        width={16}
                        alt="LinkedIn"
                        style={{ marginRight: "10px" }}
                      />
                      <Box>
                        <Typography
                          fontWeight={500}
                          fontSize={".875rem"}
                          color={"#616B73"}
                        >
                          Linkedin
                        </Typography>
                      </Box>
                    </Box>
                    <span
                      style={{
                        textDecoration: "none",
                        color: "#2F80ED",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        window.open(data?.employer?.linkedin, "_blank");
                      }}
                    >
                      <Typography>{data?.employer?.linkedin}</Typography>
                    </span>
                  </CardContent>
                )}
                {data?.employer?.facebook && (
                  <CardContent sx={{ padding: 0, mt: 3 }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-start",
                      }}
                    >
                      <img
                        src={FaceBook}
                        alt="Facebook"
                        style={{ marginRight: "10px" }}
                      />
                      <Box>
                        <Typography
                          fontWeight={500}
                          fontSize={".875rem"}
                          color={"#616B73"}
                        >
                          Facebook
                        </Typography>
                      </Box>
                    </Box>
                    <a
                      style={{ textDecoration: "none", color: " #2F80ED" }}
                      href={data?.employer?.facebook}
                      target="_blank"
                    >
                      <Typography>{data?.employer?.facebook}</Typography>
                    </a>
                  </CardContent>
                )}
                {data?.employer?.instagram && (
                  <CardContent sx={{ padding: 0, mt: 3 }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-start",
                      }}
                    >
                      <img
                        src={Instagram}
                        alt="Instagram"
                        style={{ marginRight: "10px" }}
                      />
                      <Box>
                        <Typography
                          fontWeight={500}
                          fontSize={".875rem"}
                          color={"#616B73"}
                        >
                          Instagram
                        </Typography>
                      </Box>
                    </Box>
                    <a
                      style={{ textDecoration: "none", color: " #2F80ED" }}
                      href={data?.employer?.instagram}
                      target="_blank"
                    >
                      <Typography>{data?.employer?.instagram}</Typography>
                    </a>
                  </CardContent>
                )}
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={12}>
          <Card
            sx={{
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              height: "100%",
              width: "100%",
            }}
          >
            <CardContent sx={{ p: 0 }}>
              <Box
                sx={{
                  p: { xs: 2, sm: 3 },
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{
                    fontWeight: 600,
                    color: theme?.palette?.primary?.textBtn,
                  }}
                >
                  About Organisation
                </Typography>
                {user?.is_primary && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      color: "#AB5B0B",
                    }}
                    onClick={() => {
                      setAboutEditOpen(true);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <img src={EditIcon1} alt="edit" />
                    <Typography
                      sx={{ pl: 1, fontWeight: 600, fontSize: ".875rem" }}
                    >
                      Edit
                    </Typography>
                  </Box>
                )}
              </Box>
              <Divider sx={{ mb: 2 }} />
              {!data?.employer?.about && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "200px",
                    p: 3,
                  }}
                >
                  <img src={EmptyImage} alt="empty" />
                  <Typography
                    variant="body1"
                    fontWeight={600}
                    color={theme?.palette?.primary?.textBtn}
                  >
                    No About Organisation available
                  </Typography>
                </Box>
              )}
              <Box sx={{ px: { xs: 2, sm: 3 } }}>
                <Typography
                  variant="body2"
                  color={theme?.palette?.primary?.textGrey}
                  fontWeight={500}
                  mb={2}
                >
                  {data?.employer?.about}
                </Typography>
              
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <SocialMedia
          socialMediaEditing={socialMediaEditing}
          socialMediaClose={socialMediaClose}
          data={socialMediaData}
          handleUpdate={handleUpdate}
        />
        <AboutEdit
          aboutEditOpen={aboutEditOpen}
          aboutClose={() => {
            setAboutEditOpen(false);
          }}
          data={data?.employer}
          handleUpdate={handleUpdate}
        />
      </Grid>
    </>
  );
};

export default BasicInfo;
