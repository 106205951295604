import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Dialog,
  DialogContent,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Slide,
  Stack,
  Switch,
  Typography,
  useTheme,
} from "@mui/material";
import Mind1Full from "asset/images/mind-1-full.png";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LinkedInIconColor from "asset/icons/linked-in.svg";
import { useRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { OutlinedButton } from "component/button";
import { useNavigate } from "react-router-dom";
import NoUserImage from "asset/images/no-user.png";

interface IMind {
  name: string;
  position: string;
  image: string;
  linkedIn: string;
  description: string;
}

interface IMindCardParams {
  mind: IMind;
  isEdit?: boolean;
  handleEditMindCard?: (val: any) => void;
}

const icon = (
  <Paper sx={{ m: 1, width: 100, height: 100 }} elevation={4}>
    <svg>
      <Box
        component="polygon"
        points="0,100 50,00, 100,100"
        sx={{
          fill: (theme) => theme.palette.common.white,
          stroke: (theme) => theme.palette.divider,
          strokeWidth: 1,
        }}
      />
    </svg>
  </Paper>
);

const MindCard = ({ mind, isEdit, handleEditMindCard }: IMindCardParams) => {
  const theme = useTheme();
  const [detailOpen, setDetailOpen] = useState(false);
  const [mindDetail, setMindDetail] = useState<any>({});
  const containerRef = useRef<HTMLElement>(null);
  const navigate = useNavigate();
 

  const [checked, setChecked] = useState(false);

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  const handleDetailOpen = () => {
    setDetailOpen(true);
  };

  const handleDetailClose = () => {
    setDetailOpen(false);
  };
  

  return (
    <>
      <Box ref={containerRef} display={"flex"} justifyContent={"center"}>
        <Card
          onClick={handleChange}
          sx={{
            maxWidth: "285px",
            height: "364px",
            cursor: "pointer",
            position: "relative",
          }}
        >
          <CardMedia
            component="img"
            image={mind?.image}
            alt="Image"
            height={"100%"}
          />
          <CardContent
            sx={{
              bgcolor: theme?.palette?.primary?.backgroundDarkGrey,
              p: 2,
              width: "100%",
              position: "absolute",
              height: "100%",
              top: checked ? "10%" : "80%",
              transition: "all .3s ease-out",
            }}
          >
            <Box
              height={"100%"}
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"space-between"}
            >
              <Box>
                <Stack
                  mb={2}
                  direction={"row"}
                  justifyContent={"space-between"}
                >
                  <Box>
                    <Typography
                      variant="subtitle2"
                      fontWeight={600}
                      fontSize={"1.125rem"}
                      color={theme?.palette?.primary?.textInverse}
                    >
                      {mind.name}
                    </Typography>
                    <Typography
                      variant="caption"
                      fontWeight={500}
                      fontSize={".75rem"}
                      color={theme?.palette?.primary?.textInverse}
                    >
                      {mind.position}
                    </Typography>
                  </Box>
                  <LinkedInIcon
                    sx={{ color: theme?.palette?.primary?.textInverse }}
                    onClick={($e: any) => {
                      $e.stopPropagation();
                      window.open(mind?.linkedIn, "_blank");
                    }}
                  />
                </Stack>
                <Box
                  sx={{
                    overflow: "hidden",
                    display: "-webkit-box",
                    textOverflow: "ellipsis",
                    
                    "-webkit-line-clamp": "8",
                    "-webkit-box-orient": "vertical",
                    height: "170px",
                  }}
                >
                  <Typography variant="body2" fontWeight={500} color="#FFFFFF">
                    {mind.description}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  marginBottom: "10%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <OutlinedButton
                  size="large"
                  isInverse={true}
                  isBold={true}
                  label="View Details"
                  handleClick={() => {
                    setChecked(false);
                    setDetailOpen(true);
                    setMindDetail(mind);
                  }}
                />
                {isEdit && (
                  <Box ml={3} display={"inline-block"}>
                    <OutlinedButton
                      size="large"
                      isInverse={true}
                      isBold={true}
                      label="Edit"
                      handleClick={() => {
                        setChecked(false);
                        if (handleEditMindCard) {
                          handleEditMindCard(mind);
                        }
                      }}
                    />
                  </Box>
                )}
              </Box>
            </Box>
          </CardContent>
        </Card>
        <Dialog
          open={detailOpen}
          onClose={handleDetailClose}
          PaperProps={{
            style: { maxWidth: "822px" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingRight: 2,
              position: "relative",
            }}
          >
            <IconButton
              aria-label="close"
              onClick={handleDetailClose}
              sx={{
                zIndex: 1,
                position: "absolute",
                right: 8,
                top: 8,
                color: theme?.palette?.primary?.iconOrange,
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>

          <DialogContent>
            <Box
              display="flex"
              alignItems="flex-start"
              flexDirection={{ xs: "column", sm: "row" }}
              gap={3}
            >
              <CardMedia
                sx={{
                  mt: { xs: 3, sm: 0, maxWidth: "240px", height: "325px" },
                }}
                component="img"
                image={mindDetail?.image}
                alt="Image"
              />
              <Box>
                <Box display={"flex"} minWidth={"190px"} mr={4}>
                  <Typography
                    variant="body2"
                    fontWeight={600}
                    fontSize={"20px"}
                    marginBottom={"10px"}
                  >
                    {mindDetail?.name}
                  </Typography>
                  <Divider
                    orientation="vertical"
                    variant="middle"
                    flexItem
                    sx={{ ml: 3, mr: 3 }}
                  />
                  <Box
                    component={"img"}
                    src={LinkedInIconColor}
                    height={24}
                    width={24}
                    onClick={($e: any) => {
                      $e.stopPropagation();
                      window.open(mind?.linkedIn, "_blank");
                    }}
                  />
                </Box>
                <Typography
                  variant="body2"
                  fontWeight={500}
                  fontSize={"12px"}
                  marginBottom={"10px"}
                >
                  {mindDetail?.position}
                </Typography>
                <Typography
                  variant="body2"
                  fontWeight={500}
                  color={"#616B73"}
                  fontSize={"14px"}
                >
                  {mindDetail?.description}
                </Typography>
              </Box>
            </Box>
          </DialogContent>
        </Dialog>
      </Box>
    </>
  );
};

export default MindCard;
