import {
  Box,
  Grid,
  IconButton,
  List,
  ListItem,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import MindCard from "pages/pre-launch/home/mind-card";
import mindsBehind from "pages/pre-launch/home/minds-behind";
import React, { useRef, useState } from "react";
import David from "asset/images/david.svg";
import Joseph from "asset/images/joseph.svg";
import LiamLucas from "asset/images/liam-lucas.svg";

import CompanyPhoto1 from "asset/images/company-photo-1.svg";
import CompanyPhoto2 from "asset/images/company-photo-2.svg";
import CompanyPhoto3 from "asset/images/company-photo-3.svg";
import CompanyPhoto4 from "asset/images/company-photo-4.svg";
import Testimonials from "component/testimonials";
import VideoPlayer from "component/video-player";
import Slider from "react-slick";
import EmptyImage from "asset/icons/empty.svg";
import ReactPlayer from "react-player";

const Life = ({ details, branding }: any) => {
  const theme = useTheme();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  const photosSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 3,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
    ],
  };

  return (
    <>
      <Typography
        variant="h2"
        fontWeight={600}
        color={theme?.palette?.primary?.textBtn}
        my={2}
      >
        Meet Our Leaders
      </Typography>
      <Box>
        {details?.organization?.leaders?.length === 0 ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "200px",
              p: 3,
            }}
          >
            <img src={EmptyImage} alt="empty" />
            <Typography
              variant="body1"
              fontWeight={600}
              color={theme?.palette?.primary?.textBtn}
            >
              No Leaders have been added
            </Typography>
          </Box>
        ) : (
          <>
            <Stack
              display={{ xs: "none", sm: "flex" }}
              direction={"row"}
              justifyContent={"center"}
              gap={2}
              marginTop={4}
            >
              {details?.organization?.leaders?.map((leader: any) => {
                const mind = {
                  name: leader?.first_name + " " + leader?.last_name,
                  position: leader?.designation,
                  image: leader?.profile,
                  linkedIn: leader?.linkedIn,
                  description: leader?.intro,
                };
                return <MindCard mind={mind} />;
              })}
            </Stack>
            <Stack
              component={"div"}
              display={{ xs: "block", sm: "none" }}
              className="slider-container"
            >
              {details?.organization?.leaders?.length > 1 ? (
                <Slider {...settings}>
                  {details?.organization?.leaders?.map((leader: any) => {
                    const mind = {
                      name: leader?.first_name + " " + leader?.last_name,
                      position: leader?.designation,
                      image: leader?.profile,
                      linkedIn: leader?.linkedIn,
                      description: leader?.intro,
                    };
                    return <MindCard mind={mind} />;
                  })}
                </Slider>
              ) : (
                <MindCard
                  mind={{
                    name:
                      details?.organization?.leaders[0]?.first_name +
                      " " +
                      details?.organization?.leaders[0]?.last_name,
                    position: details?.organization?.leaders[0]?.designation,
                    image: details?.organization?.leaders[0]?.profile,
                    linkedIn: details?.organization?.leaders[0]?.linkedIn,
                    description: details?.organization?.leaders[0]?.intro,
                  }}
                />
              )}
            </Stack>
          </>
        )}
      </Box>
      <Typography
        variant="h2"
        fontWeight={600}
        color={theme?.palette?.primary?.textBtn}
        my={5}
      >
        Spotlights
      </Typography>
      {details?.organization?.spotlight?.length === 0 ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "200px",
            p: 3,
          }}
        >
          <img src={EmptyImage} alt="empty" />
          <Typography
            variant="body1"
            fontWeight={600}
            color={theme?.palette?.primary?.textBtn}
          >
            No details available
          </Typography>
        </Box>
      ) : (
        <Box
          sx={{
            backgroundColor: "#F0F8FF",
            p: 5,
            my: 3,
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          {details?.organization?.spotlight?.map(
            (light: any, index: number) => {
              return (
                <Grid
                  container
                  spacing={2}
                  key={light?._id}
                  mt={index > 0 ? 2 : 0}
                >
                  <Grid item xs={12} sm={6}>
                    <Box sx={{ maxWidth: "448px", minHeight: "168px" }}>
                      <Typography
                        variant="h2"
                        fontWeight={600}
                        color={theme?.palette?.primary?.textBtn}
                      >
                        {light?.heading}
                      </Typography>
                      
                      <Typography
                        variant="subtitle2"
                        fontWeight={500}
                        color={theme?.palette?.primary?.textGrey}
                        mt={2}
                      >
                        {light?.description}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} position="relative">

                    <Box maxWidth={"550px"} minHeight={"230px"}>
                      <ReactPlayer
                        height={"100%"}
                        width={"100%"}
                        url={light?.video}
                      />
                    </Box>
                  </Grid>
                </Grid>
              );
            }
          )}
        </Box>
      )}
      <Typography
        variant="h2"
        fontWeight={600}
        color={theme?.palette?.primary?.textBtn}
        my={2}
      >
        Company Photos
      </Typography>
      {details?.organization?.photos?.length === 0 ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "200px",
            p: 3,
          }}
        >
          <img src={EmptyImage} alt="empty" />
          <Typography
            variant="body1"
            fontWeight={600}
            color={theme?.palette?.primary?.textBtn}
          >
            No Photos available
          </Typography>
        </Box>
      ) : (
        <>
          {details?.organization?.photos?.length > 1 ? (
            <Box>
              <Box
                py={{ xs: 1, md: 3, sm: 3 }}
                display={{ xs: "none", sm: "flex" }}
                gap={3}
              >
                <Box
                  className="slider-container"
                  gap={1}
                  mt={3}
                  ml={"-1rem"}
                  maxWidth={{ xs: "90vw", sm: "96vw", md: "73vw", lg: "78vw" }}
                >
                  <Slider {...photosSettings}>
                    {details?.organization?.photos?.map((photo: any) => {
                      return (
                        <Box key={photo}>
                          <Box
                            component={"img"}
                            src={photo}
                            alt="Company Photo"
                            sx={{
                              width: { sm: "180px", lg: "95%", xl: "248px" },
                              height: { sm: "180px", lg: "240px", xl: "248px" },
                              borderRadius: "8px",
                            }}
                          />
                        </Box>
                      );
                    })}
                  </Slider>
                </Box>
              </Box>
              <Box display={{ xs: "block", sm: "none" }}>
                <Grid container spacing={3}>
                  {details?.organization?.photos?.map((photo: string) => {
                    return (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <Box
                          component={"img"}
                          src={photo}
                          alt="Company Photo"
                          sx={{
                            width: "280px",
                            height: "280px",
                            borderRadius: "8px",
                          }}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            </Box>
          ) : (
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Box
                  component={"img"}
                  src={details?.organization?.photos[0]}
                  alt="Company Photo"
                  sx={{
                    width: { lg: "95%", xl: "248px" },
                    height: { lg: "240px", xl: "248px" },
                    borderRadius: "8px",
                  }}
                />
              </Grid>
            </Grid>
          )}

        
        </>
      )}
      <Box mb={5} maxWidth={{ xs: "90vw", sm: "96vw", md: "73vw", lg: "78vw" }}>
        <Testimonials branding={branding} />
      </Box>
    </>
  );
};

export default Life;
