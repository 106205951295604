import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
  FormControlLabel,
  Paper,
  Slide,
  Stack,
  Switch,
  useTheme,
  CardContent,
  Card,
  IconButton,
  Breadcrumbs,
  Link,
} from "@mui/material";
import React, { useRef, useState } from "react";
import BannerFrame from "asset/images/banner-frame.svg";
import BannerFrame1 from "asset/images/banner-frame-1.svg";
import About from "asset/images/about-image.png";
import { OutlinedButton } from "component/button";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DiverseSkillSet from "asset/icons/diverse-skill-set.svg";
import SpecialisedTraining from "asset/icons/specialised-training.svg";
import DisciplineAndWorkEthic from "asset/icons/discipline.svg";
import BenefitsEkam from "asset/images/BenefitsEkam.svg";
import MindCard from "pages/pre-launch/home/mind-card";
import Mind1 from "asset/images/Client-1.png";
import Mind2 from "asset/images/Client-2.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import Veterans from "asset/images/veterans.svg";
import DIverseSkiilSet1 from "asset/images/diverse-skill-set-1.svg";
import PrivateSector from "asset/images/private-sector.svg";
import PauseCircleOutlineRoundedIcon from "@mui/icons-material/PauseCircleOutlineRounded";
import PlayCircleOutlineOutlinedIcon from "@mui/icons-material/PlayCircleOutlineOutlined";
import PlayIcon from "asset/icons/play.svg";

import Tcs from "asset/images/companies/tcs.svg";
import Wipro from "asset/images/companies/wipro.svg";
import Ibm from "asset/images/companies/ibm.svg";
import Facebook from "asset/images/companies/facebook.svg";
import Infosys from "asset/images/companies/infosys.svg";
import Bosch from "asset/images/companies/bosch.svg";
import Flipkart from "asset/images/companies/flipkart.svg";
import Etisalat from "asset/images/companies/etisalat.svg";
import TestimonialCard from "forms/hoc/testimonial-card";
import EllipseDotBg from "asset/images/ellipse-dot.svg";
import EllipseImage from "asset/images/ellipse.svg";
import Testimonials from "component/testimonials";

const AboutUs = () => {
  const theme = useTheme();
  const matchDownSm = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
    ],
  };
  const mindsBehind: any = [
    {
      name: "Rashmi Pathak",
      position: "CEO and Co-Founder",
      image: Mind1,
      linkedIn: "https://www.linkedin.com/in/rashmi-pathak-2b408011/",
      description:
        "Rashmi is a compassionate human resources professional and a proud military wife. Before embarking on her entrepreneurial journey, Rashmi spent over 17 years at Genpact (NYSE: G) working in HR and banking operations. She led numerous global programs focused on HR transformation, new hire experience, and recruitment. Her passion for building innovative solutions combined with a people-centric approach led her to form 'EKAM' with the purpose of serving those who serve the nation. Having closely observed the challenges many military veterans face as they transition into civilian life, as well as the gaps organizations encounter while hiring diverse veteran talent, Ekam was established to break down barriers, promote equal opportunities, and create a more inclusive workforce.She lives with her family in Gurugram, India.",
    },
    {
      name: "Nikhilesh KVD",
      position: "CTO and Co-Founder",
      image: Mind2,
      linkedIn: "https://www.linkedin.com/in/nikhileshkvd/",
      description:
        "Nikhilesh is a visionary, award-winning technology leader whose journey from being the son of a veteran to an IAF aspirant and eventually to the corporate world embodies resilience and innovation. With over 25 years of experience, he brings in unique perspective to the tech industry, blending operational ingenuity with creativity to pioneer ground-breaking solutions. As a passionate supporter and advocate for veteran empowerment in the corporate realm, he co-founded EKAM as its Chief Technology Officer (CTO). His fundamental belief is for EKAM to serve as an experience-led technology platform facilitating the end-to-end journey of a veteran's transition to the private sector.",
    },
  ];
  const [checked, setChecked] = useState(false);

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  const [expanded, setExpanded] = useState<string | false>(false);

  const handlePanelChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const testimonialsSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
    ],
  };
  const videoRef = useRef<any>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          backgroundColor: theme?.palette?.primary?.backgroundOrangeMid,
          mx: { xs: "-2%", sm: "-7.5rem" },
        }}
      >
        <Box>
          <Box
            sx={{
              ml: { xs: "2%", sm: "3rem" },
              pl: { sm: "4.5rem" },
              mt: 2,
            }}
          >
            <Typography>
              Welcome to <b>Ekam</b>
            </Typography>
          </Box>
          <Box
            sx={{
              backgroundImage: matchDownSm ? "" : `url(${EllipseImage})`,
              backgroundRepeat: "no-repeat",
              ml: { xs: "2%", sm: "3rem" },
              pl: { sm: "4.5rem" },

              mb: 3,
              mt: { xs: 1, sm: 3 },
              minHeight: { sm: "300px" },
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography
              fontSize={{ xs: "2rem", sm: "3rem" }}
              color={theme?.palette?.primary?.textBtn}
              fontWeight={600}
            >
              About us
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "1rem", sm: "1.125rem" },
                maxWidth: "588px",
                fontWeight: 500,
                color: theme?.palette?.primary?.textMid,
                mb: 3,
                mt: 1,
              }}
            >
              Empowering veterans to transition into corporate careers by
              connecting them with opportunities that leverage their unique
              skills and experience
            </Typography>
            <Box
              sx={{
                xs: "-2%",
                sm: "-7.5rem",
                display: { xs: "block", sm: "none" },
              }}
            >
              <img
                src={BannerFrame1}
                alt="Servicesforcandidates"
                style={{ maxWidth: "100%" }}
              />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            xs: "-2%",
            sm: "-7.5rem",
            display: { xs: "none", sm: "block" },
          }}
        >
          <img
            src={BannerFrame}
            alt="Servicesforcandidates"
            style={{ maxWidth: "100%" }}
          />
        </Box>
      </Box>

      <Box sx={{ mt: 6 }}>
        <Typography variant="h2" sx={{ fontWeight: 600 }}>
          Know us better
        </Typography>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} lg={6}>
            <Typography
              variant="subtitle2"
              sx={{
                fontWeight: 500,
                color: theme?.palette?.primary?.textMid,
              }}
            >
              Ekam is a social enterprise platform focused on solving the
              problem of diversity, equity and inclusion in the workplace, with
              a specific focus on military veterans. We stand for Equality
              Knowledge Awareness & Mobility, our unique product enables the
              diverse talent to leverage a common system and platform to access
              millions of job opportunities, reach and build communities and
              peer networks, access free/ paid learning modules and get mentored
              by successful people coming from similar backgrounds. Military
              personnel serve their life protecting our motherland and
              post-retirement many look forward to contributing to building the
              nation by working in the corporate sector. Their integrity,
              leadership, work ethics and core military values make them a
              coveted group which many organizations can benefit from.
            </Typography>
          </Grid>
          <Grid item xs={12} lg={6} md={6}>
            <Box position={"relative"}>
              <Box
                ref={videoRef}
                component={"video"}
                src={require("asset/videos/Pahlakadam.mp4")}
                autoPlay={false}
                borderRadius={1.5}
                maxWidth={"calc(100vw - 16px)"}
                maxHeight={"325px"}
              ></Box>
              <IconButton
                sx={{
                  position: "absolute",
                  bottom: 8,
                  left: { lg: "28rem", xs: "18rem", sm: "31rem" },

                  "& .MuiSvgIcon-root": {
                    fontSize: "83.3px",
                  },
                }}
                onClick={() => {
                  if (isPlaying) {
                    videoRef.current.pause();
                    setIsPlaying(false);
                  } else {
                    videoRef.current.play();
                    setIsPlaying(true);
                  }
                }}
              >
                {isPlaying ? (
                  <PauseCircleOutlineRoundedIcon
                    sx={{
                      color: theme.palette.primary.textInverse,
                      fontSize: "2.75rem", // Use 'rem' for fontSize
                    }}
                  />
                ) : (
                  <>
                    <PlayCircleOutlineOutlinedIcon
                      sx={{
                        color: theme.palette.primary.textInverse,
                        fontSize: "2.75rem", // Use 'rem' for fontSize
                      }}
                    />
                  </>
                )}
              </IconButton>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="subtitle2"
              sx={{
                fontWeight: 500,
                color: theme?.palette?.primary?.textMid,
              }}
            >
              We at Ekam bridge the gap by bringing together organizations,
              recruiters, job seekers, and professionals on a common platform.
              We are driven by our belief that diverse and inclusive work
              environments enable innovation, foster creativity, and ultimately
              lead to better business outcomes.
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={{
          backgroundColor: theme.palette.primary.backgroundDarkGrey,
          backgroundImage: `url(${EllipseDotBg})`,
          backgroundRepeat: "no-repeat",
          backgroundPositionY: "3rem",
          backgroundSize: "100%",
          mt: 7,
          padding: 2.5,
          mx: { xs: "-2%", sm: "-7.5rem" },
          px: { xs: "2%", sm: "7.5rem" },
        }}
      >
        <Box
          sx={{
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            mt: "5%",
          }}
        >
          <Grid container spacing={6}>
            <Grid item xs={12} md={6}>
              <Box>
                <Typography
                  variant="h2"
                  sx={{ color: " #FFFFFF", fontWeight: 600 }}
                >
                  Our mission
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: theme?.palette?.primary?.textInverse,
                    marginTop: 1.2,
                    fontWeight: 500,
                  }}
                >
                  Empowering veterans beyond service
                </Typography>
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: theme?.palette?.primary?.textInverse,
                    marginTop: 3,
                  }}
                >
                  We thrive to connect job seekers from diverse backgrounds,
                  genders, ethnicities, abilities, and orientations with
                  organizations, providing them with an equal opportunity to
                  fulfilling careers.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box>
                <Typography
                  variant="h2"
                  sx={{
                    color: theme?.palette?.primary?.textInverse,
                    fontWeight: 600,
                  }}
                >
                  Our vision
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: theme?.palette?.primary?.textInverse,
                    marginTop: 1.2,
                    fontWeight: 500,
                  }}
                >
                  Tailored support to veterans
                </Typography>
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: theme?.palette?.primary?.textInverse,
                    marginTop: 3,
                    fontWeight: 500,
                  }}
                >
                  Deliver 1M jobs through our platform focused on the diversity
                  talent pool.
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: { xs: "flex-start", sm: "center" },
              marginTop: { xs: 2.5, md: 1.6 },
              color: theme?.palette?.primary?.textInverse,
              fontWeight: 600,
              fontVariant: "h4",
              mb: 4,
            }}
          >
            <OutlinedButton
              isInverse={true}
              size={"small"}
              label={"Talk to Us"}
              handleClick={() => {
                navigate("/contact-us");
              }}
            />
          </Box>
        </Box>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        marginTop={1.25}
        flexDirection={{ xs: "column", sm: "row" }}
      >
        <Box
          sx={{
            maxWidth: { xs: "100%", sm: "50%" },
            backgroundColor: "white",
            padding: 2.5,
            mt: 5,
          }}
        >
          <Typography variant="h2" sx={{ fontWeight: 600 }}>
            The 1 million placement dream
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{
              fontWeight: 500,
              maxWidth: "497px",
              mt: 2,
              color: theme?.palette?.primary?.textMid,
            }}
          >
            Military veterans bring in numerous skills and capabilities that
            could benefit your organisation.
          </Typography>
          <Box
            sx={{
              mt: 4,
              display: { fontSize: 1.125, sm: "block", xs: "none" },
            }}
          >
            <OutlinedButton
              size={"small"}
              label={"Become a Member"}
              isBold={true}
              handleClick={() => {
                navigate("/candidate/register");
              }}
            ></OutlinedButton>
          </Box>
        </Box>

        <Box
          sx={{
            maxWidth: { xs: "100%", sm: "45%" },
            mb: "5%",
            display: "flex",
            gap: 2,
            mt: 4,
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <Box
            sx={{
              maxWidth: { xs: "100%", sm: "256px" },
              backgroundColor: "white",
              mb: 3,
              display: "flex",
              flexDirection: { xs: "row", sm: "column" },
              justifyContent: "flex-start",
              alignItems: { xs: "center", sm: "baseline" },
              position: "relative",
              boxShadow: `0px 4px 12px ${theme?.palette?.primary?.boxShadowLight}`,
              p: 3,
              borderRadius: 1,
              gap: 2,
            }}
          >
            <img
              src={DIverseSkiilSet1}
              alt="Diverse skill set"
              style={{
                width: "60px",
                height: "60px",
              }}
            />
            <Typography
              variant="h4"
              sx={{
                paddingLeft: 1.25,
                fontWeight: 500,

                color: theme?.palette?.primary?.textBtn,
                minWidth: "208px",
              }}
            >
              Ekam ran by military family for veterans
            </Typography>
          </Box>
          <Box
            sx={{
              maxWidth: { xs: "100%", sm: "256px" },
              backgroundColor: "white",
              mb: 3,
              display: "flex",
              flexDirection: { xs: "row", sm: "column" },
              justifyContent: "flex-start",
              alignItems: { xs: "center", sm: "baseline" },
              position: "relative",
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
              p: 3,
              borderRadius: 1,
              gap: 2,
            }}
          >
            <img
              src={PrivateSector}
              alt="Private sector solutions"
              style={{
                width: "60px",
                height: "60px",
              }}
            />
            <Typography
              variant="h4"
              sx={{
                paddingLeft: 1.2,
                fontWeight: 500,

                color: theme?.palette?.primary?.textBtn,
              }}
            >
              Bringing private sector solutions to public sector challenges
            </Typography>
          </Box>
          <Box sx={{ display: { sm: "none", xs: "block" } }}>
            <OutlinedButton
              size={"small"}
              label={"Become a Member"}
              handleClick={() => {
                navigate("/candidate/register");
              }}
            ></OutlinedButton>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          mx: {
            xs: "-2%",
            sm: "-7.5rem",
          },
          px: { xs: "2%", sm: "7.5rem" },
          py: 5,
          backgroundColor: theme?.palette?.primary?.backgroundBlueLight,
          mt: 4,
        }}
      >
        <Box
          display="flex"
          gap={2}
          justifyContent={"space-between"}
          flexDirection={{ xs: "column", sm: "row" }}
          lineHeight={2}
        >
          <Box flex={2} maxWidth={"588px"} sx={{ order: { lg: 1, xs: 2 } }}>
            <Typography
              variant="h2"
              sx={{
                fontWeight: 600,
                color: theme.palette.primary.textBtn,
                mb: "2rem",
              }}
            >
              Why veterans?
            </Typography>

            <Accordion
              sx={{
                backgroundColor: theme.palette.primary.backgroundBlueLight,
                "&.Mui-expanded::before": {
                  opacity: 1,
                },
              }}
              expanded={expanded === "panel1"}
              onChange={handlePanelChange("panel1")}
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    sx={{ color: theme.palette.primary.iconGreyDark }}
                  />
                }
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontWeight: 600,
                  color: theme.palette.primary.textBtn,
                }}
              >
                <img
                  src={DiverseSkillSet}
                  alt="Diverse skill set"
                  style={{ marginRight: "0.7rem" }}
                />
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: expanded === "panel1" ? 600 : 500,
                    color:
                      expanded === "panel1"
                        ? theme.palette.primary.textBtn
                        : theme.palette.primary.textMid,
                  }}
                >
                  Diverse skill set
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  fontWeight: 500,
                  color: theme.palette.primary.textMid,
                }}
              >
                Military personnel often possess a diverse skill set
                that includes leadership, teamwork, problem-solving, and
                adaptability, which are highly valuable in the corporate sector.
              </AccordionDetails>
            </Accordion>

            <Accordion
              sx={{
                backgroundColor: theme.palette.primary.backgroundBlueLight,
                "&.Mui-expanded::before": {
                  opacity: 1,
                },
              }}
              expanded={expanded === "panel2"}
              onChange={handlePanelChange("panel2")}
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    sx={{ color: theme.palette.primary.iconGreyDark }}
                  />
                }
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontWeight: 500,
                  color: theme.palette.primary.textMid,
                }}
              >
                <img
                  src={SpecialisedTraining}
                  alt="Specialised training"
                  style={{ marginRight: "0.7rem" }}
                />
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: expanded === "panel2" ? 600 : 500,
                    color:
                      expanded === "panel2"
                        ? theme.palette.primary.textBtn
                        : theme.palette.primary.textMid,
                  }}
                >
                  Specialised training
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  fontWeight: 500,
                  color: theme.palette.primary.textMid,
                }}
              >
                Military personnel often possess a diverse skill set that
                includes leadership, teamwork, problem-solving, and
                adaptability, which are highly valuable in the corporate sector.
              </AccordionDetails>
            </Accordion>

            <Accordion
              sx={{
                backgroundColor: theme.palette.primary.backgroundBlueLight,
                "&.Mui-expanded::before": {
                  opacity: 1,
                },
              }}
              expanded={expanded === "panel3"}
              onChange={handlePanelChange("panel3")}
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    sx={{ color: theme.palette.primary.iconGreyDark }}
                  />
                }
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontWeight: 500,
                  color: theme.palette.primary.textMid,
                }}
              >
                <img
                  src={DisciplineAndWorkEthic}
                  alt="Discipline and work ethic"
                  style={{ marginRight: "0.7rem" }}
                />
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: expanded === "panel3" ? 600 : 500,
                    color:
                      expanded === "panel3"
                        ? theme.palette.primary.textBtn
                        : theme.palette.primary.textMid,
                  }}
                >
                  Discipline and work ethic
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  fontWeight: 500,
                  color: theme.palette.primary.textMid,
                }}
              >
                Military personnel often possess a diverse skill set that
                includes leadership, teamwork, problem-solving, and
                adaptability, which are highly valuable in the corporate sector.
              </AccordionDetails>
            </Accordion>
          </Box>

          <Box sx={{ order: { lg: 2, xs: 1 } }}>
            <img
              src={Veterans}
              alt="BenefitsEkam"
              style={{ maxWidth: "100%" }}
            />
          </Box>
        </Box>
      </Box>
      <Box mt={{ xs: 3, sm: 6 }} sx={{ maxWidth: "100%" }}>
        <Typography
          variant="h2"
          sx={{ color: theme?.palette?.primary?.textBtn, fontWeight: 600 }}
        >
          Minds behind Ekam
        </Typography>
        <Typography
          variant="subtitle2"
          sx={{
            color: theme?.palette?.primary?.textMid,
            fontWeight: 500,
            marginBottom: 5,
            mt: 2,
          }}
        >
          Driven by a Purpose and Passion to Support Those Who Serve Our Nation.
        </Typography>
      </Box>
      <Box>
        <Stack
          display={{ xs: "none", sm: "flex" }}
          direction={"row"}
          justifyContent={"center"}
          gap={2}
        >
          {mindsBehind.map((mind: any) => {
            return <MindCard mind={mind} />;
          })}
        </Stack>
        <Box
          component={"div"}
          display={{ xs: "block", sm: "none" }}
          className="slider-container"
        >
          <Slider {...settings}>
            {mindsBehind.map((mind: any) => {
              return <MindCard mind={mind} />;
            })}
          </Slider>
        </Box>
      </Box>
      <Box
        sx={{
          padding: 5,
          backgroundColor: theme.palette.primary.backgroundOrangeLight,

          mt: 7,
          mx: { xs: "-2%", sm: "-7.5rem" },
          px: { xs: "2%", sm: "7.5rem" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",

            mt: 2,
          }}
        >
          <Box>
            <Typography variant="h2" sx={{ fontWeight: 600, marginBottom: 1 }}>
              Our Trusted Partners
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ color: theme?.palette?.primary?.textMid, fontWeight: 500 }}
            >
              Partnering with top employers to unlock true potential of veteran
              talent
            </Typography>
          </Box>
          <Box display={{ xs: "none", sm: "block" }}>
            <OutlinedButton
              size={"small"}
              label={"Partner with Us"}
              isBold={true}
              handleClick={() => {
                navigate("/employer/register");
              }}
            />
          </Box>
        </Box>
        <Box component={"section"} mt={4}>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            gap={3}
            sx={{
              overflowX: "auto",
            }}
          >
            <Box component={"img"} src={Tcs} alt="TCS" />
            <Box component={"img"} src={Wipro} alt="Wipro" />
            <Box component={"img"} src={Ibm} alt="Ibm" />
            <Box component={"img"} src={Facebook} alt="Facebook" />
            <Box component={"img"} src={Infosys} alt="Infosys" />
            <Box component={"img"} src={Bosch} alt="Bosch" />
            <Box component={"img"} src={Flipkart} alt="Flipkart" />
            <Box component={"img"} src={Etisalat} alt="Etisalat" />
          </Box>
        </Box>
        <Box
          display={{ xs: "block", sm: "none" }}
          textAlign={"center"}
          marginTop={3}
        >
          <OutlinedButton
            size={"small"}
            label={"Partner with Us"}
            handleClick={() => {
              navigate("/employer/register");
            }}
          />
        </Box>
      </Box>

      <Box mt={5} mb={10}>
        <Testimonials />
      </Box>
    </Box>
  );
};

export default AboutUs;
