import {
  Box,
  Breadcrumbs,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Link,
  Modal,
  Stack,
  Tab,
  Typography,
  useTheme,
} from "@mui/material";
import BasicInfo from "./basic-info";
import EmployerLife from "./life";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import EmployerProfileImage from "asset/images/employer-profile-image.svg";
import Notification from "asset/icons/notification.svg";
import EditIcon from "asset/icons/edit.svg";
import EllipseImage from "asset/images/ellipse-image.svg";
import CloseIcon from "@mui/icons-material/Close";
import ProfileImage from "asset/icons/no-profile.svg";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import Jobs from "pages/post-launch/candidate/employer-details/jobs";
import OverView from "pages/post-launch/candidate/employer-details/overview";
import Life from "pages/post-launch/candidate/employer-details/life";
import Location from "asset/images/location.svg";
import SetTheme from "./set-theme";
import { Dropdown } from "component/dropdown";
import SetCoverImage from "./set-cover-image";
import { commonService } from "forms/helpers/common.service";
import { errorMessage } from "forms/helpers/function.helper";
import { Failed, Success } from "forms/helpers/toast.helper";
import { useAppSelector } from "store/store";
import Loader from "ui-component/Loader";
import NoCompanyImage from "asset/images/no-company.svg";
import { InputField, MultiSelectField } from "forms/hoc/formfield";
import { ProfileValidationSchema } from "forms/hooks/validateRules";
import useForm from "forms/hooks/useForm";
import { readFile } from "helpers/function.helper";
import { ContainedButton, OutlinedButton } from "component/button";
import ImageUploadWithCrop from "component/HOC/image-upload";

const EmployerProfile = () => {
  const theme = useTheme();
  const [newLeaderImage, setNewLeaderImage] = useState<any>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [profileImageModalOpen, setProfileImageModalOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState<any>("");
  const user = useAppSelector((state) => state.user.user);
  const [industries, setIndustries] = useState([]);
  const childRef: any = useRef();
  const handleEditThemeColorClick = () => {
    setModalOpen(true);
  };
  const [value, setValue] = useState("basic-info");
  const [profileDetails, setProfileDetails] = useState<any>(null);
  const [basicDetails, setBasicDetails] = useState<any>(null);
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const [themeOption, setThemeOption] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const themeOptions = [
    {
      name: "Edit Cover Image",
      value: "cover-image",
    },
    {
      name: "Edit Color",
      value: "color",
    },
  ];
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "12px",
    p: 4,
  };

  const [modalopen, setModalOpen] = useState(false);
  const handleClose = () => setModalOpen(false);
  const handleOpen = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setNewLeaderImage(profileDetails?.employer?.profile);
    setUpdateValue(
      "organization_name",
      profileDetails?.employer?.organization_name
    );
    setUpdateValue(
      "industries",
      profileDetails?.employer?.industries.map((industry: any) => industry?._id)
    );

    setOpen(true);
  };
  const handleUpdateProfile = () => {
    const data = {
      organization_name: values.organization_name,
      industries: values.industries,
    };

    if (values?.profile) {
      let profileData: FormData = new FormData();
      profileData.append("profile", values?.profile);
      commonService
        .postService(`/employer/files/${user?.user?.id}`, profileData)
        .then((response: any) => {
          delete values?.profile;
          handleUpdate(data);
          handleClose();
        })
        .catch((error) => {
          setIsLoading(false);
          Failed(
            errorMessage(error, "Something went wrong, Please try again later")
          );
        });
    } else {
      delete values?.profile;
      handleUpdate(data);
      handleClose();
    }
  };

  const profile = {
    organization_name: null,
    industries: [],
  };
  const { values, errors, handleChange, setUpdateValue, handleSubmit } =
    useForm(profile, handleUpdateProfile, ProfileValidationSchema);

  useEffect(() => {
    getProfileDetails();
    getIndustriesmaster();
  }, []);

  const getProfileDetails = () => {
    setIsLoading(true);
    let params: any = {
      page: 1,
      per_page: 10,
      sort_order: "ASC",
      sort_by: "created_at",
    };

    const payload = {
      company: [user?.user?.id],
    };

    commonService
   
      .getServices("recruiter/recruiters")
      .then((response) => {
        setIsLoading(false);
  
        setProfileDetails(response?.data?.data);
      })
      .catch((error) => {
        setIsLoading(false);
        Failed(
          errorMessage(error, "Something went wrong, Please try again later")
        );
      });
  };

  const handleUpdate = (data: any) => {
    setIsLoading(true);
    commonService
      .patchService(`employer?employerId=${user?.user?.id}`, data)
      .then((response: any) => {
        setIsLoading(false);
        Success(response?.data?.message);
        getProfileDetails();
      })
      .catch((error) => {
 
        setIsLoading(false);
        Failed(errorMessage(error, "Something went wrong!"));
      });
  };
  function getIndustriesmaster() {
    commonService
      .postService(`/master/industries`, {})
      .then((res: any) => {
        const locationData = res?.data?.data?.results?.map((item: any) => {
          return {
            name: item?.name,
            value: item?._id,
          };
        });
        setIndustries(locationData);
      })
      .catch((err) => console.log("Error: ", err));
  }
  const handleCreateIconClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const handleFileInputChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      let imageDataUrl = await readFile(file);
      setImageUrl(imageDataUrl);
      setProfileImageModalOpen(true);
    }
  };

  const handleApplyClick = async () => {
    if (childRef.current) {
      const result = await childRef.current.showCroppedImage();
      setImageUrl("");
      setNewLeaderImage(URL.createObjectURL(result));
      setUpdateValue("profile", result);
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <Breadcrumbs
        aria-label="breadcrumb"
        sx={{ py: 2, display: { sm: "none", xs: "block" } }}
      >
        <Link
          underline="hover"
          color="inherit"
          sx={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/employer/home");
          }}
        >
          Home
        </Link>
        <Typography fontWeight={500} color={theme?.palette?.primary?.textMid}>
          Employer - Profile
        </Typography>
      </Breadcrumbs>
      <Box
        sx={{
          mt: "-3px",
          mx: { xs: "-2%", sm: "-2%", md: "-7.5rem" },
          px: { xs: "2%", sm: "7.5rem" },
          backgroundImage: profileDetails?.employer?.is_featured
            ? `url(${profileDetails?.employer?.cover_image})`
            : "none",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
          height: { xs: "120px", sm: "210px" },
          position: "relative",
          backgroundColor: "#E2F2FF",
        }}
      >
        <Breadcrumbs
          aria-label="breadcrumb"
          sx={{ pt: 2, display: { sm: "block", xs: "none" } }}
        >
          <Link
            underline="hover"
            color="inherit"
            sx={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/employer/home");
            }}
          >
            Home
          </Link>
          <Typography
            fontWeight={500}
            color={theme.palette.primary.textInverse}
          >
            Employer - Profile
          </Typography>
        </Breadcrumbs>
        <Box
          sx={{
            position: "absolute",
            bottom: { xs: "-135px", sm: "-123px" },
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            minWidth: { sm: "calc(100vw - 15rem)" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: { xs: "column", sm: "row" },
            }}
          >
            <Box
              sx={{
                display: { xs: "flex", sm: "flex" },
                minWidth: { xs: "90vw", md: "0", sm: "0" },
                justifyContent: { xs: "space-between" },
              }}
            >
              <Box
                component="img"
                src={
                  profileDetails?.employer?.profile
                    ? profileDetails?.employer?.profile
                    : NoCompanyImage
                }
                alt="R image"
                sx={{
                  display: "flex",
                  position: "relative",
                  top: { xs: 32, sm: 0 },
                  height: { xs: 80, sm: 180 },
                  width: { xs: 80, sm: 180 },
                  borderRadius: "50%",
                }}
              />
              <Box mt={6} sx={{ display: { sm: "none", xs: "block" } }}>
                {profileDetails?.employer?.is_featured && user?.is_primary && (
                  <Dropdown
                    label="Edit Theme"
                    options={themeOptions}
                 
                    isInverse={true}
                    id="theme"
                    handleSelect={(value: any) => {
                      setThemeOption(value);
                      handleEditThemeColorClick();
                    }}
                  />
                )}
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                mt: 6,
                ml: 1,
                minWidth: { xs: "90vw", sm: "0", md: "0" },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h3"
                  sx={{
                    fontWeight: 600,
                    color: theme?.palette?.primary?.textBtn,
                  }}
                >
                  {profileDetails?.employer?.organization_name}
                </Typography>
                {user?.is_primary && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      ml: 3,
                      cursor: "pointer",
                    }}
                    onClick={handleClickOpen}
                  >
                    <img src={EditIcon} alt="edit" />
                    <Typography
                      sx={{
                        pl: 1,
                        fontWeight: 600,
                        fontSize: ".875rem",
                        color: "#AB5B0B",
                        cursor: "pointer",
                      }}
                    >
                      Edit
                    </Typography>
                  </Box>
                )}
              </Box>
              {profileDetails?.employer?.industries?.length > 0 && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    mt: 3,
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      fontWeight={500}
                      fontSize={"1rem"}
                      color={theme?.palette?.primary?.textGrey}
                    >
                      {profileDetails?.employer?.industries[0]?.name}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      ml: 2.5,
                      gap: 2,
                    }}
                  >
                    {profileDetails?.employer?.industries?.length === 2 && (
                      <Box>
                        <Box sx={{ display: "flex", gap: 2 }}>
                          <img src={EllipseImage} alt="ellipse" />
                          <Typography
                            fontWeight={500}
                            fontSize={"1rem"}
                            color={theme?.palette?.primary?.textGrey}
                          >
                            {profileDetails?.employer?.industries[1]?.name}
                          </Typography>
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Box>
              )}
            </Box>
          </Box>

          <Box mt={"-170px"} sx={{ display: { sm: "block", xs: "none" } }}>
            {profileDetails?.employer?.is_featured && user?.is_primary && (
              <Dropdown
                label="Edit Theme"
                options={themeOptions}
               
                isInverse={true}
                id="theme"
                handleSelect={(value) => {
                  setThemeOption(value);
                  handleEditThemeColorClick();
                }}
              />
            )}
            {modalopen && (
              <Modal open={modalopen}>
                <Box sx={style}>
                  <IconButton
                    sx={{
                      position: "absolute",
                      right: "2px",
                      top: "1px",
                    }}
                    onClick={handleClose}
                  >
                    <CloseIcon
                      sx={{
                        fontSize: "20px",
                        color: theme.palette.primary.iconOrange,
                      }}
                    />
                  </IconButton>
                  {themeOption === "cover-image" && (
                    <SetCoverImage
                      handleClose={() => {
                        setModalOpen(false);
                      }}
                      getData={getProfileDetails}
                    />
                  )}
                  {themeOption === "color" && (
                    <SetTheme
                      handleClose={() => {
                        setModalOpen(false);
                      }}
                    />
                  )}
                  {!modalopen && (
                    <Box>
                      <Box>
                        <img src={EllipseImage} alt="ellipse" />
                        <Typography
                          fontWeight={500}
                          fontSize={"1rem"}
                          color={theme?.palette?.primary?.textGrey}
                        >
                          {profileDetails?.employer?.industries[1]?.name}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </Box>
              </Modal>
            )}
          </Box>
        </Box>

        <Dialog open={open} onClose={handleClose}>
          <DialogTitle
            sx={{
              fontWeight: 700,
              fontSize: "20px",
              color: " #000000",
            }}
          >
            Edit Profile Details
          </DialogTitle>
          <form onSubmit={handleSubmit}>
            <DialogContent>
              <Box
                display={"flex"}
                gap={{ xs: 0, sm: 3 }}
                flexDirection={{ xs: "column", sm: "row" }}
              >
                <Box display={"flex"} justifyContent={"center"}>
                  <Box position="relative">
                    <Box
                      component={"img"}
                      height={120}
                      width={120}
                      src={newLeaderImage ? newLeaderImage : ProfileImage}
                      alt="Profile Image"
                      sx={{
                        backgroundColor: "#F0F8FF",
                        borderRadius: "50%",
                      }}
                    />
                    <Box
                      sx={{
                        position: "absolute",
                        top: 85,
                        right: 0,
                        backgroundColor: "#192732",
                        borderRadius: "50%",
                        padding: "10px",
                        cursor: "pointer",
                        display: "flex",
                        flexDirection: { xs: "column", sm: "row" }, // or "row" depending on your needs
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      onClick={handleCreateIconClick}
                    >
                      <img
                        src={EditIcon}
                        alt="Create Icon"
                        style={{ width: "15px", height: "15px" }}
                      />
                    </Box>
                    <input
                      type="file"
                      ref={fileInputRef}
                      name="profile"
                      accept="image/gif, image/jpeg, image/png"
                      style={{ display: "none" }}
                      onChange={handleFileInputChange}
                    />
                    <Modal
                      open={imageUrl !== ""}
                      onClose={() => {
                        setImageUrl("");
                      }}
                    >
                      <Box sx={style}>
                        <IconButton
                          sx={{
                            position: "absolute",
                            right: "2px",
                            top: "1px",
                          }}
                          onClick={() => {
                            setImageUrl("");
                          }}
                        >
                          <CloseIcon
                            sx={{
                              fontSize: "20px",
                              color: theme.palette.primary.iconOrange,
                            }}
                          />
                        </IconButton>
                        <Box>
                          <ImageUploadWithCrop
                            imageUrl={imageUrl}
                            ref={childRef}
                            aspect={4 / 4}
                          />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            gap: 3,
                            alignItems: "center",
                            mt: 3,
                            justifyContent: "space-between",
                          }}
                        >
                          <OutlinedButton
                            label="Cancel"
                            size="small"
                            isBold={true}
                            handleClick={() => {
                              setImageUrl("");
                            }}
                          />

                          <ContainedButton
                            label="Apply"
                            size="small"
                            isBold={true}
                            handleClick={handleApplyClick}
                          />
                        </Box>
                      </Box>
                    </Modal>
                  </Box>
                </Box>
                <Stack spacing={2}>
                  <InputField
                    fieldName="Organisation name"
                    values={values.organization_name}
                    name="organization_name"
                    errors={errors.organization_name}
                    handleChange={handleChange}
                    isRequired={false}
                    id="organization_name"
                  />
                  <Box width={{ xs: "208px", sm: "323px" }}>
                    <MultiSelectField
                      fieldName="Industry"
                      values={values?.industries || []}
                      name="industries"
                      isRequired={false}
                      multiple={true}
                      errors={errors?.industries}
                      handleChange={($e: any) => {
                        setUpdateValue("industries", $e?.target?.value);
                        
                      }}
                      placeholder="Industries"
                      options={industries}
                      id="industries"
                    />
                  </Box>
                </Stack>
              </Box>
            </DialogContent>
            <Box
              display={"flex"}
              justifyContent={"end"}
              marginBottom={3}
              marginRight={3}
            >
              <OutlinedButton
                size="small"
                label="Cancel"
                type="button"
                handleClick={handleOpen}
              />
              <Box width={16} />
              <ContainedButton
                size="small"
                isBold={true}
                label="Save"
                type="submit"
                handleClick={handleOpen}
              />
            </Box>
          </form>
        </Dialog>
      </Box>

      <Box sx={{ marginTop: "130px" }}>
        <TabContext value={value}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <TabList
              variant="scrollable"
              scrollButtons="auto"
              onChange={handleTabChange}
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                mt: 1.87,
                flex: "10px",
              }}
            >
              <Tab label="Basic Info" value="basic-info" />
              {profileDetails?.employer?.is_featured && (
                <Tab label="Life" value="life" />
              )}
            </TabList>
          </Box>
          <TabPanel value="basic-info">
            <BasicInfo data={profileDetails} handleUpdate={handleUpdate} />
          </TabPanel>
          <TabPanel value="life">
            <EmployerLife
              data={profileDetails}
              handleUpdate={handleUpdate}
              getProfileData={getProfileDetails}
            />
          </TabPanel>
        </TabContext>
      </Box>
    </>
  );
};

export default EmployerProfile;
